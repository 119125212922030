<template>
  <v-layout fill-height :style="$vuetify.theme.dark ? '' : 'background: white'">
    <v-col cols="4" :style="`background: ${$vuetify.theme.dark ? '#1E1E1E' : 'lightgrey'}`">
      <QuestionInfo :question="question" @refresh="init" />
    </v-col>
    <v-col cols="8">
      <AnswerInfo :question="question" :recommendationAnswers="coreReplyList" @refresh="init" />
    </v-col>
  </v-layout>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'
import QuestionInfo from '@/components/QuestionInfo'
import AnswerInfo from '@/components/AnswerInfo'

export default defineComponent({
  name: 'QuestionUpdate',

  components: { AnswerInfo, QuestionInfo },

  setup(props, { root }) {
    const state = reactive({
      question: null,
      coreReplyList: [],
    })

    const init = async () => {
      await getOneMyQuestion()
      await getListCoreReplyFromQuestion()
    }

    const getOneMyQuestion = async () => {
      try {
        state.question = await root.$store.dispatch('question/getOneMyQuestion', {
          id: Number(root.$route.params.uid),
        })
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const getListCoreReplyFromQuestion = async () => {
      try {
        const coreReplyList = await root.$store.dispatch('question/getListCoreReplyFromQuestion', {
          questionId: Number(state.question.id),
        })
        state.coreReplyList = JSON.parse(JSON.stringify(coreReplyList))
        state.coreReplyList = state.coreReplyList.map((e) => {
          e.stamp = [e.stamp]
          return e
        })
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    onMounted(() => {
      init()
    })

    return {
      ...toRefs(state),
      init,
      getListCoreReplyFromQuestion,
    }
  },
})
</script>
