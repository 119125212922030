<template>
  <v-dialog v-model="isShow" hide-overlay>
    <v-card>
      <v-card-title>
        유저 질문내역
        <v-spacer />
        <v-btn class="cancel" color="error" @click="isShow = false">
          닫기
        </v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="data.questions" :items-per-page="-1" :sort-by="['createdAt']" :sort-desc="[true]">
        <template v-slot:item.stamp="{ item }">
          <QuestionTag :tags="item.stamp" />
        </template>
        <template v-slot:item.content="{ item }">
          {{ preview(item.content, 100) }}
        </template>
        <template v-slot:item.reply="{ item }">
          <VueMarkdown
            v-if="item.reply"
            style="font-size: 13px"
            :source="`${item.reply.introduction}\n${item.reply.body}\n${item.reply.conclusion}\n${columnList(item.reply)}\n${reportList(item.reply)}`"
            height="300px"
          />
          <span v-else>답변 대기중</span>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import QuestionTag from './QuestionTag'
import { preview } from '@/helper'
import VueMarkdown from 'vue-markdown'

export default defineComponent({
  components: { QuestionTag, VueMarkdown },

  setup(props, { root }) {
    const state = reactive({
      mounted: false,
      isShow: false,
      data: {
        questions: [],
      },
      headers: [
        { text: 'ID', value: 'id', width: '5%' },
        { text: '라벨', value: 'stamp', width: '10%' },
        { text: '질문', value: 'content', sortable: false, width: '20%' },
        { text: '답변', value: 'reply' },
      ],
    })

    const columnList = (reply) => {
      if (reply.Column && reply.Column[0]) {
        let data = "#### <span style='color:orange'>칼럼</span>\n"

        reply.Column.forEach((e) => {
          if (!e.fish) data += `[${e.id}] ${e.title}\n`
        })

        return data
      } else return ''
    }

    const reportList = (reply) => {
      if (reply.Column && reply.Column[0]) {
        let data = "#### <span style='color:#7f00ff'>레포트</span>\n"

        reply.Column.forEach((e) => {
          if (e.fish) data += `[${e.id}] ${e.title}\n`
        })

        return data
      } else return ''
    }

    const getListQuestion = async (userId) => {
      try {
        const res = await root.$store.dispatch('question/getListQuestion', {
          userId: Number(userId),
          sort: {
            what: 'createdAt',
            sort: 'desc',
          },
        })

        state.data.questions = res.data
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const show = (userId) => {
      getListQuestion(userId)
      state.isShow = true
    }

    return {
      ...toRefs(state),
      columnList,
      reportList,
      show,
      preview,
    }
  },
})
</script>
