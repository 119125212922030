<template>
  <v-row>
    <v-col v-for="(e, i) in select" :key="i" :cols="3">
      <v-select
        v-model="depth[e.model]"
        outlined
        dense
        hide-details
        :prepend-inner-icon="`mdi-numeric-${i + 1}-box`"
        :disabled="e.disabled ? !depth[e.disabled] : false"
        :items="res[e.model]"
        :append-icon="null"
        color="#ffd600"
        clearable
      />
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs, watch } from '@vue/composition-api'

export default defineComponent({
  props: {
    value: {
      type: Object,
    },
    cols: {
      type: Number,
      default: 4,
    },
  },

  setup(props, { root, emit }) {
    const state = reactive({
      updated: false,
      select: [
        { model: 'firstDepth', disabled: null },
        { model: 'secondDepth', disabled: 'firstDepth' },
        { model: 'thirdDepth', disabled: 'secondDepth' },
      ],
      res: {
        firstDepth: [],
        secondDepth: [],
        thirdDepth: [],
      },
      depth: {
        id: null,
        firstDepth: '',
        secondDepth: '',
        thirdDepth: '',
      },
    })

    const selectDepth = async (firstDepth, secondDepth) => {
      const newStamp = await root.$store.dispatch('stamp/getNewStamp', {
        firstDepth: firstDepth ?? undefined,
        secondDepth: secondDepth ?? undefined,
      })

      state.depth.id = null

      for (const i in newStamp) {
        state.depth.thirdDepth && state.depth.thirdDepth === newStamp[i].thirdDepth
          ? (state.depth.id = newStamp[i].id)
          : secondDepth && !state.depth.thirdDepth && newStamp[i].thirdDepth === null
          ? (state.depth.id = newStamp[i].id)
          : firstDepth && !secondDepth && newStamp[i].secondDepth === null
          ? (state.depth.id = newStamp[i].id)
          : null
      }

      newStamp.map((e) => {
        if (e.firstDepth !== null) state.res.firstDepth.push(e.firstDepth)
        else if (e.secondDepth !== null) state.res.secondDepth.push(e.secondDepth)
        else if (e.thirdDepth !== null) state.res.thirdDepth.push(e.thirdDepth)
      })
    }

    const init = async () => {
      state.updated = false

      await selectDepth()
      const firstDepth = props.value?.firstDepth
      state.depth.firstDepth = firstDepth
      if (firstDepth) await selectDepth(firstDepth)
      const secondDepth = props.value?.secondDepth
      state.depth.secondDepth = secondDepth
      if (secondDepth) await selectDepth(firstDepth, secondDepth)
      state.depth.thirdDepth = props.value?.thirdDepth

      state.updated = true
    }

    watch(
      () => state.depth.firstDepth,
      () => {
        state.res.secondDepth = []
        state.depth.secondDepth = null
        if (state.updated) selectDepth(state.depth.firstDepth)
      },
    )

    watch(
      () => state.depth.secondDepth,
      () => {
        state.res.thirdDepth = []
        state.depth.thirdDepth = null
        if (state.updated) selectDepth(state.depth.firstDepth, state.depth.secondDepth)
      },
    )

    watch(
      () => state.depth.thirdDepth,
      () => {
        if (state.updated) selectDepth(state.depth.firstDepth, state.depth.secondDepth)
      },
    )

    watch(
      state.depth,
      () => {
        if (state.updated) emit('input', state.depth)
      },
      { deep: true },
    )

    watch(() => props.value, init)

    onBeforeMount(() => {
      init()
    })

    return {
      ...toRefs(state),
      selectDepth,
    }
  },
})
</script>
