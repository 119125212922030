<template>
  <div v-if="question">
    <v-card class="pa-3 mb-3" flat :color="$vuetify.theme.dark ? '#272727' : 'white'">
      <v-list-item>
        <v-list-item-avatar size="70" color="grey lighten-2">
          <img v-if="question.questioner.profileLink" :src="question.questioner.profileLink" />
        </v-list-item-avatar>
        <v-list-item-content>
          <div>
            <b>
              {{
                `${question.questioner.name} (${question.questioner.id}) ${
                  question.questioner.sex === 'male' ? '남성' : question.questioner.sex === 'female' ? '여성' : '그 외'
                }`
              }}
            </b>
            <div class="pt-1">
              {{ userInfo }}
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <div class="mb-3 d-flex">
      <v-btn class="mr-3" style="flex: 1" large depressed @click="$refs.userQuestionList.show(question.questioner.id)">
        <b>질문내역</b>
      </v-btn>
      <v-btn large style="flex: 2" @click="$refs.userQuestionList.show(question.questioner.id)">
        <b>{{ question.questioner.questions.length }}건</b>
      </v-btn>
    </div>
    <v-card flat class="mb-3">
      <StatusTag
        bold
        block
        large
        :tag="{
          show: question.status,
          reply: question.reply ? true : false,
        }"
        @click="approvalQuestion"
      />
    </v-card>

    <v-card class="mb-3 pa-3" flat :color="$vuetify.theme.dark ? '#272727' : 'white'" @click="openUpdateStampDialog">
      <QuestionTag diff-key="question" :tags="question.stamp" />
    </v-card>

    <v-card class="pa-3" flat :color="$vuetify.theme.dark ? '#272727' : 'white'">
      <VueMarkdown style="font-size: 14px" :source="question.content" />
    </v-card>

    <UpdateStampDialog ref="updateStampDialog" @refresh="$emit('refresh')" />
    <UserQuestionList ref="userQuestionList" />
  </div>
</template>

<script>
import { defineComponent, reactive, computed, toRefs } from '@vue/composition-api'
import QuestionTag from '@/components/QuestionTag'
import UpdateStampDialog from '@/components/dialog/UpdateStampDialog'
import UserQuestionList from '@/components/UserQuestionList'
import StatusTag from '@/components/StatusTag'
import VueMarkdown from 'vue-markdown'

export default defineComponent({
  components: {
    QuestionTag,
    UpdateStampDialog,
    UserQuestionList,
    StatusTag,
    VueMarkdown,
  },

  props: { question: Object },

  setup(props, { root, emit }) {
    const state = reactive({
      updateStampDialog: null,
      education: {
        halfAgain: '반수',
        nAgain: '재수',
        parents: '학부모',
        homeSchool: '검정고시',
        other: '기타',
      },
      middleSchoolKind: {
        regular: '일반중',
        international: '국제중',
        art: '예술중',
        physical: '체육중',
        alternative: '대안중',
        other: '기타중',
      },
      highSchoolKind: {
        regular: '일반고',
        international: '국제고',
        art: '예술고',
        physical: '체육고',
        gited: '영재고',
        science: '과고',
        foreign: '외고',
        meister: '마이스터고',
        autonomous: '자사고',
        autonomousPublic: '자공고',
        characterization: '특성화고',
        technical: '공고',
        other: '기타고',
      },
    })

    const userInfo = computed(() => {
      const data = { neisSchool: '', education: '', year: '' }
      const schoolInfo = props.question.questioner.schoolInfo
      if (schoolInfo.education === 'high') {
        data.neisSchool = schoolInfo.neisSchool
        data.education = state.highSchoolKind[schoolInfo.highSchoolKind] ?? schoolInfo.neisSchoolKind
        data.year = schoolInfo.year + '학년'
      } else if (schoolInfo.education === 'middle') {
        data.neisSchool = schoolInfo.neisSchool
        data.education = state.middleSchoolKind[schoolInfo.middleSchoolKind] ?? schoolInfo.neisSchoolKind
        data.year = schoolInfo.year + '학년'
      } else if (schoolInfo.education === 'elementary') {
        data.neisSchool = schoolInfo.neisSchool
        data.year = schoolInfo.year + '학년'
      } else {
        data.education = state.education[schoolInfo.education]
        if (schoolInfo.education.includes('Again')) data.year = schoolInfo.year + '년차'
      }
      return `${data.neisSchool} ${data.education} ${data.year}`
    })

    const openUpdateStampDialog = () => {
      state.updateStampDialog.show('질문', props.question.id)
    }

    const approvalQuestion = async () => {
      try {
        if (props.question.status !== 'pending') return

        await root.$store.dispatch('question/approvalQuestion', {
          questionId: Number(props.question.id),
        })

        emit('refresh')
      } catch (err) {
        console.error(err)
      }
    }

    return {
      ...toRefs(state),
      userInfo,
      openUpdateStampDialog,
      approvalQuestion,
    }
  },
})
</script>
