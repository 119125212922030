<template>
  <div v-if="!isRecommendAnswer">
    <v-tabs v-model="tabs" color="deep-purple accent-2" :background-color="$vuetify.theme.dark ? '#121212' : ''">
      <v-tab>추천답변</v-tab>
      <v-tab>칼럼</v-tab>
      <v-tab>레포트</v-tab>
      <v-spacer />
      <v-btn @click="$router.push('/question')">
        나가기
      </v-btn>
    </v-tabs>
    <v-divider class="mb-3" :style="`border: 1px solid ${backgroundColor}; position: relative; top: -2px`" />
    <v-row>
      <v-col cols="6">
        <div v-if="tabs === 0">
          <div class="mb-3">
            <v-textarea
              v-model="reply.introduction"
              solo
              filled
              :readonly="!!question.reply"
              :background-color="backgroundColor"
              flat
              hide-details
              placeholder="서론"
              rows="3"
              auto-grow
            />
          </div>
          <v-card v-if="question.reply" flat class="mb-3 px-3 py-1" style="height: calc(100vh - 330px); overflow-y: auto" :color="backgroundColor">
            <VueMarkdown :source="question.reply.body" style="font-size: 13px" />
          </v-card>
          <v-card v-else :color="backgroundColor" flat class="mb-3" style="height: calc(100vh - 330px); overflow-y: auto">
            <draggable v-model="myList" class="mb-3" style="height: 100%" :disabled="!enabled" ghost-class="ghost" group="people" @add="saveEachCoreReply">
              <v-card v-for="(item, idx) in myList" :key="item.id + idx" flat class="ma-3">
                <v-container>
                  <div class="mb-1">
                    <QuestionTag small :tags="item.stamp" />
                    <QuestionInvisibleTag small :tags="item.invisibleStamp ? item.invisibleStamp.map((e) => e.name) : null" />
                  </div>
                  <div class="mb-1">
                    <b>{{ item.title }}</b>
                  </div>
                  <VueMarkdown style="font-size: 13px" :source="item.main" />
                </v-container>
                <v-card-actions>
                  <v-spacer />
                  <v-btn icon @click="openRecommendAnswer(idx, myList[idx])">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="saveCoreReply(item)">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn icon color="error" @click="myList.splice(idx, 1)">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
              <div slot="footer" class="ma-3 pb-3" @click="openRecommendAnswer(myList.length)">
                <v-btn block light color="#ffd600">
                  <v-icon>mdi-plus</v-icon>
                  추가하기
                </v-btn>
              </div>
            </draggable>
          </v-card>
          <div class="mb-3">
            <v-textarea
              v-model="reply.conclusion"
              solo
              hide-details
              placeholder="결론"
              flat
              color="#ffd600"
              :readonly="!!question.reply"
              :background-color="backgroundColor"
              rows="3"
              auto-grow
            />
          </div>
        </div>
        <div v-else>
          <v-card :color="backgroundColor" flat class="mb-3" style="height: calc(100vh - 140px); overflow-y: auto">
            <draggable v-if="tabs === 1" v-model="columnList" class="mb-3" style="height: 100%" :disabled="!enabled" ghost-class="ghost" group="people">
              <v-card v-for="(item, idx) in columnList" :key="item.id + idx" flat class="ma-3">
                <v-container>
                  <div class="mb-1">
                    <QuestionTag small :tags="item.stamp" />
                    <QuestionInvisibleTag small :tags="item.invisibleStamp.map((e) => e.name)" />
                  </div>
                  <div class="mb-1">
                    <b>{{ `[${item.id}] ${item.title}` }}</b>
                  </div>
                </v-container>
                <v-card-actions v-if="!question.reply">
                  <v-spacer />
                  <v-btn icon color="error" @click="columnList.splice(idx, 1)">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </draggable>

            <draggable v-else-if="tabs === 2" v-model="reportList" class="mb-3" style="height: 100%" :disabled="!enabled" ghost-class="ghost" group="people">
              <v-card v-for="(item, idx) in reportList" :key="item.id + idx" flat class="ma-3">
                <v-container>
                  <div class="mb-1">
                    <QuestionTag small :tags="item.stamp" />
                    <QuestionInvisibleTag small :tags="item.invisibleStamp.map((e) => e.name)" />
                  </div>
                  <div class="mb-1">
                    <b>{{ `[${item.id}] ${item.title}` }}</b>
                  </div>
                </v-container>
                <v-card-actions v-if="!question.reply">
                  <v-spacer />
                  <v-btn icon color="error" @click="reportList.splice(idx, 1)">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </draggable>
          </v-card>
        </div>

        <v-btn color="#ffd600" :disabled="!!question.reply" light block @click="createReply">
          발송
        </v-btn>
      </v-col>

      <v-divider vertical class="my-6" :style="`border: 1px solid ${backgroundColor}`" />

      <v-col class="pl-3">
        <div class="mb-3">
          <div style="width: 100%">
            <v-text-field
              v-model="query"
              autocomplete="off"
              hide-details
              label="Search"
              :background-color="backgroundColor"
              flat
              solo
              class="mb-3"
              @keyup="searchCoreReply"
            />
            <v-combobox
              v-model="selectedInvisibleTags"
              :items="invisibleStamps"
              multiple
              :search-input.sync="search"
              :background-color="backgroundColor"
              deletable-chips
              chips
              label="투명라벨"
              hide-details
              flat
              solo
              class="mb-3"
              @change="searchCoreReply"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      검색 결과가 없습니다. "
                      <b>{{ search }}</b>
                      ". 새롭게 추가하시려면
                      <kbd>enter</kbd>
                      를 눌러주세요
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
        </div>

        <v-row v-show="tabs === 1 || tabs === 2" no-gutters class="pb-3">
          <v-col v-for="(e, i) in select" :key="i" cols="4">
            <v-select
              v-show="tabs === 1"
              v-model="req.columnStamp[e.model]"
              :background-color="backgroundColor"
              dense
              hide-details
              :prepend-inner-icon="`mdi-numeric-${i + 1}-box`"
              :append-icon="null"
              :items="res.columnStamp[e.model]"
              :disabled="e.disabled ? !req.columnStamp[e.disabled] : false"
              outlined
              :clearable="Boolean(i)"
              color="#ffd600"
              single-line
              @change="changeDepth('columnStamp', i)"
            />
            <v-select
              v-show="tabs === 2"
              v-model="req.reportStamp[e.model]"
              :background-color="backgroundColor"
              dense
              hide-details
              :prepend-inner-icon="`mdi-numeric-${i + 1}-box`"
              :append-icon="null"
              :items="res.reportStamp[e.model]"
              :disabled="e.disabled ? !req.reportStamp[e.disabled] : false"
              outlined
              :clearable="Boolean(i)"
              color="#ffd600"
              single-line
              @change="changeDepth('reportStamp', i)"
            />
          </v-col>
        </v-row>

        <div v-if="!question.reply">
          <v-card flat class="px-3 pt-3" :style="`background: ${backgroundColor}`" style="height: calc(100vh - 262px); overflow-y: auto">
            <draggable
              v-if="recommendAnswer"
              v-model="recommendAnswer"
              class="mb-3"
              :disabled="!enabled"
              ghost-class="ghost"
              :group="{ name: 'people', pull: 'clone', put: false }"
            >
              <v-card v-for="item in recommendAnswer" :key="'recommendAnswer' + item.id" flat class="mb-3">
                <v-container>
                  <v-btn
                    icon
                    style="position: absolute; right: 5px; top: 5px"
                    @click="tabs === 0 ? myList.push(item) : tabs === 1 ? columnList.push(item) : reportList.push(item)"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>

                  <QuestionTag small :tags="item.stamp" />
                  <QuestionInvisibleTag v-if="item.invisibleStamp" class="mb-1" small :tags="item.invisibleStamp.map((e) => e.name)" />

                  <div v-if="tabs === 0" class="mb-1">
                    <b v-html="item.titleMark || item.title" />
                  </div>

                  <div v-else class="mb-1">
                    <b v-html="`[${item.id}] ${item.titleMark || item.title}`" />
                  </div>

                  <VueMarkdown v-show="tabs === 0" style="font-size: 13px" :source="item.mark || item.main" />
                </v-container>
              </v-card>
            </draggable>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-card-title class="pb-0">추천답변 작성하기</v-card-title>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>제목</v-list-item-title>
        <v-text-field v-model="recommendAnswerItem.title" outlined dense hide-details />
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>라벨</v-list-item-title>
        <LabelInput v-model="recommendAnswerItem.stamp[0]" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>투명라벨</v-list-item-title>
        <InvisibleStampInput v-model="recommendAnswerItem.invisibleStamp" />
      </v-list-item-content>
    </v-list-item>
    <MarkdownEditor v-model="recommendAnswerItem.main" class="ma-4" height="1" style="height: calc(100vh - 244px)" />
    <v-card-actions>
      <v-spacer />
      <v-btn dark color="red" @click="closeRecommendAnswer(false)">취소</v-btn>
      <v-btn dark color="primary" @click="closeRecommendAnswer(true)">
        확인
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { defineComponent, reactive, computed, toRefs, watch, onBeforeMount } from '@vue/composition-api'
import QuestionTag from '@/components/QuestionTag'
import QuestionInvisibleTag from '@/components/QuestionInvisibleTag'
import MarkdownEditor from '@/components/MarkdownEditor'
import LabelInput from '@/components/input/Label.vue'
import InvisibleStampInput from '@/components/input/InvisibleStamp.vue'
import draggable from 'vuedraggable'
import VueMarkdown from 'vue-markdown'
import { getRegExp } from 'korean-regexp'

export default defineComponent({
  props: {
    question: Object,
    recommendationAnswers: Array,
  },

  components: {
    VueMarkdown,
    draggable,
    QuestionTag,
    QuestionInvisibleTag,
    MarkdownEditor,
    LabelInput,
    InvisibleStampInput,
  },

  setup(props, { root, emit }) {
    const state = reactive({
      select: [
        { model: 'firstDepth', disabled: null },
        { model: 'secondDepth', disabled: 'firstDepth' },
        { model: 'thirdDepth', disabled: 'secondDepth' },
      ],
      isRecommendAnswer: false,
      recommendAnswerIdx: 0,
      recommendAnswerItem: {
        title: '',
        main: '',
        stamp: [],
        invisibleStamp: [],
      },
      tabs: 0,
      selectedInvisibleTags: [],
      query: '',
      enabled: true,
      myList: [],
      columnList: [],
      reportList: [],
      invisibleStamps: [],
      search: null,
      recommendAnswer: [],
      columns: [],
      reports: [],
      reply: {
        introduction: '',
        body: '',
        conclusion: '',
        coreReply: [],
      },
      req: {
        columnStamp: {
          firstDepth: '',
          secondDepth: '',
          thirdDepth: '',
        },
        reportStamp: {
          firstDepth: '',
          secondDepth: '',
          thirdDepth: '',
        },
      },
      res: {
        columnStamp: {
          firstDepth: [],
          secondDepth: [],
          thirdDepth: [],
        },
        reportStamp: {
          firstDepth: [],
          secondDepth: [],
          thirdDepth: [],
        },
      },
    })

    const backgroundColor = computed(() => (root.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.16)' : 'rgba(0, 0, 0, 0.06)'))

    const createReply = async () => {
      if (props.question.reply) {
        alert('이미 답변한 질문 입니다.')
        return
      }

      const checkTitleIsNullList = state.myList.filter((l) => l.title == null)
      if (checkTitleIsNullList.length > 0) {
        alert('질문 제목에 아무 값을 넣지 않고 보낼 수 없습니다.')
        return
      }
      try {
        if (!confirm('답변을 보내면 더이상 수정할 수 없습니다.')) return
        console.log(state.myList)

        await root.$store.dispatch('question/createReply', {
          userId: Number(props.question.questioner.id),
          introduction: state.reply.introduction,
          bodyList: state.myList.map((e) => ({
            id: e.id ? Number(e.id) : null,
            title: e.title,
            stampList: e.stamp ? [Number(e.stamp[0].id)] : null,
            invisibleStampList: e.invisibleStamp ? e.invisibleStamp.map((e) => e.name) : null,
            main: e.main,
            isSaveCoreReply: e.isSaveCoreReply,
          })),
          conclusion: state.reply.conclusion,
          questionId: Number(props.question.id),
          mentorId: 30,
          columnIdList: state.columnList.map((x) => Number(x.id)),
          reportIdList: state.reportList.map((x) => Number(x.id)),
        })
        alert('답변이 저장되었습니다.')
        emit('refresh')
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const getListColumn = async () => {
      try {
        state.columns = await root.$store.dispatch('column/getListColumn', {
          firstDepth: state.req.columnStamp.firstDepth ?? undefined,
          secondDepth: state.req.columnStamp.secondDepth ?? undefined,
          thirdDepth: state.req.columnStamp.thirdDepth ?? undefined,
          toPublishedAt: new Date(),
          sort: {
            what: 'publishedAt',
            sort: 'desc',
          },
        })

        state.columns = state.columns.data
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const getListReport = async () => {
      try {
        state.reports = await root.$store.dispatch('report/getListReport', {
          firstDepth: state.req.reportStamp.firstDepth ?? undefined,
          secondDepth: state.req.reportStamp.secondDepth ?? undefined,
          thirdDepth: state.req.reportStamp.thirdDepth ?? undefined,
          toPublishedAt: new Date(),
          sort: {
            what: 'publishedAt',
            sort: 'desc',
          },
        })

        state.reports = state.reports.data
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const saveCoreReply = (item) => {
      if (confirm('추천 답변으로 저장됩니다.')) item.isSaveCoreReply = true
    }

    const saveEachCoreReply = (id) => {
      if (state.myList.length === 1) state.myList[0].isSaveCoreReply = false
      else state.myList[id.newIndex].isSaveCoreReply = false
    }

    const searchCoreReply = () => {
      let data
      if (state.tabs === 0) {
        data = props.recommendationAnswers
      } else if (state.tabs === 1) {
        data = state.columns
      } else if (state.tabs === 2) {
        data = state.reports
      }

      if (state.selectedInvisibleTags[0]) {
        state.recommendAnswer = data.filter((x) =>
          x.invisibleStamp ? x.invisibleStamp.filter((y) => state.selectedInvisibleTags.map((x) => x.text || x).includes(y.name))[0] : false,
        )
      } else state.recommendAnswer = data

      let regExp = getRegExp(state.query.trim(), {
        initialSearch: true,
        global: true,
      })

      state.recommendAnswer = state.recommendAnswer.filter((x) => regExp.test(`[${x.id}] ${x.title} ${x.main}`))
      if (String(regExp) === '/(?:)/gi') {
        state.recommendAnswer.map((x) => {
          x.mark = null
          x.titleMark = null
        })
      } else {
        state.recommendAnswer.map((x) => {
          x.titleMark = x.title.replace(regExp, '<mark>$&</mark>')
          x.mark = x.main.replace(regExp, '<mark>$&</mark>')
        })
      }
    }

    const reload = () => {
      if (state.tabs === 0) {
        state.recommendAnswer = props.recommendationAnswers
      } else if (state.tabs === 1) {
        state.recommendAnswer = state.columns
      } else if (state.tabs === 2) {
        state.recommendAnswer = state.reports
      }
    }

    const selectDepth = async (name, firstDepth, secondDepth) => {
      try {
        const stamp = await root.$store.dispatch('stamp/getNewStamp', {
          firstDepth: firstDepth ?? undefined,
          secondDepth: secondDepth ?? undefined,
        })

        if (state.req[name].secondDepth) state.res[name].thirdDepth = []
        else if (state.req[name].firstDepth) state.res[name].secondDepth = []

        stamp.map((e) => {
          if (e.firstDepth !== null) state.res[name].firstDepth.push(e.firstDepth)
          else if (e.secondDepth !== null) state.res[name].secondDepth.push(e.secondDepth)
          else if (e.thirdDepth !== null) state.res[name].thirdDepth.push(e.thirdDepth)
        })
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const openRecommendAnswer = (idx, item) => {
      if (item) {
        state.recommendAnswerItem = item
      }
      state.recommendAnswerIdx = idx
      state.isRecommendAnswer = true
    }

    const closeRecommendAnswer = (save) => {
      if (save) {
        state.myList[state.recommendAnswerIdx] = state.recommendAnswerItem
      }
      state.isRecommendAnswer = false
      state.recommendAnswerItem = {
        title: '',
        main: '',
        stamp: [],
        invisibleStamp: [],
      }
    }

    onBeforeMount(async () => {
      const res = await root.$store.dispatch('stamp/getListInvisibleStamp')
      state.invisibleStamps = res.map((e) => ({
        text: e.name,
      }))
    })

    const changeDepth = async (name, number) => {
      if (number === 1) {
        state.req[name].thirdDepth = null
        if (state.req[name].secondDepth) selectDepth(name, state.req[name].firstDepth, state.req[name].secondDepth)
      } else if (number === 0) {
        state.req[name].secondDepth = null
        state.req[name].thirdDepth = null
        if (state.req[name].firstDepth) selectDepth(name, state.req[name].firstDepth)
      }

      await getListColumn()
      await getListReport()

      reload()
      searchCoreReply()
    }

    watch(() => props.recommendationAnswers, reload)

    watch(
      () => props.question,
      () => {
        if (props.question.reply != null) {
          state.reply.introduction = props.question.reply.introduction
          state.reply.body = props.question.reply.body
          state.reply.conclusion = props.question.reply.conclusion
          state.columnList = props.question.reply.Column
          state.reportList = props.question.reply.Column

          state.reportList = state.reportList.filter((e) => {
            if (e.fish) return e
          })

          state.columnList = state.columnList.filter((e) => {
            if (!e.fish) return e
          })
        }

        state.req.columnStamp = { ...props.question.stamp[0] }
        state.req.reportStamp = { ...props.question.stamp[0] }

        getListColumn()
        getListReport()

        selectDepth('columnStamp')
        if (state.req.columnStamp.firstDepth) selectDepth('columnStamp', state.req.columnStamp.firstDepth)
        if (state.req.columnStamp.secondDepth) selectDepth('columnStamp', state.req.columnStamp.firstDepth, state.req.columnStamp.secondDepth)
        selectDepth('reportStamp')
        if (state.req.reportStamp.firstDepth) selectDepth('reportStamp', state.req.reportStamp.firstDepth)
        if (state.req.reportStamp.secondDepth) selectDepth('reportStamp', state.req.reportStamp.firstDepth, state.req.reportStamp.secondDepth)
      },
    )

    watch(
      () => state.tabs,
      () => {
        reload()
        state.query = ''
        state.selectedInvisibleTags = []
      },
    )

    return {
      ...toRefs(state),
      backgroundColor,
      changeDepth,
      selectDepth,
      createReply,
      saveCoreReply,
      saveEachCoreReply,
      searchCoreReply,
      getListColumn,
      getListReport,
      openRecommendAnswer,
      closeRecommendAnswer,
    }
  },
})
</script>
