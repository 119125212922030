<template>
  <v-dialog v-model="isShow" max-width="300">
    <v-card>
      <v-card-title>라벨 변경하기</v-card-title>
      <v-card-text>
        <v-list-item>
          <v-list-item-content>
            <v-select
              v-for="(e, i) in labelList"
              :key="i"
              v-model="req.select[e.model]"
              :prepend-icon="`mdi-numeric-${i + 1}-box`"
              :disabled="e.disabled ? !req.select[e.disabled] : null"
              :items="select[e.model]"
              :append-icon="null"
              color="#ffd600"
              clearable
              outlined
              dense
              :menu-props="{
                maxHeight: 1024,
              }"
            />
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn class="cancel" color="error" @click="isShow = false">
          취소
        </v-btn>
        <v-btn class="submit" color="primary" @click="changeQuestionStamp">
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, reactive, onBeforeMount, watch, toRefs } from '@vue/composition-api'

export default defineComponent({
  setup(props, { root, emit }) {
    const state = reactive({
      type: null,
      isShow: false,
      labelList: [{ model: 'firstDepth' }, { model: 'secondDepth', disabled: 'firstDepth' }, { model: 'thirdDepth', disabled: 'secondDepth' }],
      select: {
        firstDepth: [],
        secondDepth: [],
        thirdDepth: [],
      },
      req: {
        id: null,
        selectedTags: [],
        select: {
          firstDepth: null,
          secondDepth: null,
          thirdDepth: null,
        },
      },
    })

    const show = (type, id) => {
      state.type = type
      state.req.id = id
      state.isShow = true
    }

    const selectFirstDepth = async () => {
      const getNewStamp = await root.$store.dispatch('stamp/getNewStamp', {
        firstDepth: state.req.select.firstDepth,
      })

      state.select.secondDepth = []
      getNewStamp.map((e) => {
        if (e.secondDepth !== null) state.select.secondDepth.push(e.secondDepth)
        else state.req.selectedTags[0] = e.id
      })
    }

    const selectSecondDepth = async () => {
      const getNewStamp = await root.$store.dispatch('stamp/getNewStamp', {
        firstDepth: state.req.select.firstDepth,
        secondDepth: state.req.select.secondDepth,
      })

      state.select.thirdDepth = []
      getNewStamp.map((e) => {
        if (e.thirdDepth !== null) state.select.thirdDepth.push(e.thirdDepth)
        else state.req.selectedTags[0] = e.id
      })
    }

    const selectThirdDepth = async () => {
      const getNewStamp = await root.$store.dispatch('stamp/getNewStamp', {
        firstDepth: state.req.select.firstDepth,
        secondDepth: state.req.select.secondDepth,
      })

      getNewStamp.map((e) => {
        if (e.thirdDepth === state.req.select.thirdDepth) state.req.selectedTags[0] = e.id
      })
    }

    const changeQuestionStamp = async () => {
      try {
        await root.$store.dispatch('question/changeQuestionStamp', {
          id: Number(state.req.id),
          stampList: [Number(state.req.selectedTags[0])],
        })

        state.isShow = false
        emit('refresh')
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    onBeforeMount(async () => {
      const getNewStamp = await root.$store.dispatch('stamp/getNewStamp', {})

      getNewStamp.map((e) => {
        state.select.firstDepth.push(e.firstDepth)
      })
    })

    watch(
      () => state.req.select.firstDepth,
      () => {
        state.req.select.secondDepth = null
        state.req.select.thirdDepth = null
        if (state.req.select.firstDepth) selectFirstDepth()
      },
    )

    watch(
      () => state.req.select.secondDepth,
      () => {
        state.req.select.thirdDepth = null
        if (state.req.select.secondDepth) selectSecondDepth()
        else if (state.req.select.firstDepth) selectFirstDepth()
      },
    )

    watch(
      () => state.req.select.thirdDepth,
      () => {
        if (state.req.select.thirdDepth) selectThirdDepth()
        else if (state.req.select.secondDepth) selectSecondDepth()
        else if (state.req.select.firstDepth) selectFirstDepth()
      },
    )

    return {
      ...toRefs(state),
      changeQuestionStamp,
      show,
    }
  },
})
</script>
