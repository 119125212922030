<template>
  <div>
    <v-chip v-for="(tag, idx) in tags" :key="idx" label color="#888" text-color="#FFF" :class="`mr-1 mt-1`" :small="small">
      {{ tag }}
    </v-chip>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: { tags: [Array, Object], dffKey: String, small: Boolean },
})
</script>
